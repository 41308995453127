// Home
import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <main className="flex flex-col items-center justify-center min-h-screen p-4">
            <h1 className="text-3xl font-bold mb-4 text-center"> {/* Added text-center here */}
                Advanced, Rapid & Agile Data Capability As A Service
            </h1>
            <p className="text-lg text-gray-600 mb-8 max-w-2xl text-center">
                Hi, I'm Alvin and I'll be your Data Concierge today!
                I am a specialist in Data Science, Business Process Automation, and Project Management for SAP Implementations and Finance Transformation Programmes.
                I provide Data Concierge services to Accelerate and Energise your project.
            </p>
            <Link to="/about" className="bg-customGrey2 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
                About Me
            </Link>

        </main>
    );
};

export default Home;
