// src/SignUp.js
import React, { useState } from 'react';
import { signUp } from '../auth'; // Adjust the path as necessary

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message

    try {
      await signUp(email, password);
      alert('User signed up successfully!');
      // Optionally, redirect to another page or clear the form
    } catch (error) {
      setError(error.message); // Set error message to display
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 border border-gray-300 rounded-lg shadow-md bg-white">
      <h2 className="text-2xl font-semibold text-center mb-4">Sign Up</h2>
      {error && <p className="text-red-500 text-center mb-4">{error}</p>} {/* Display error message */}
      <form onSubmit={handleSignUp} className="flex flex-col space-y-4"> {/* Added space-y-4 for vertical spacing */}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button type="submit" className="p-2 bg-customGrey2 text-white rounded hover:bg-blue-600 transition duration-200">
          Sign Up
        </button>
      </form>
    </div>
  );
};

export default SignUp;
