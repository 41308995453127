// src/About.js
import React from 'react';
import Gallery from '../components/Gallery'; // Import the Gallery component

const About = () => {
    return (
        <main className="flex flex-col items-center justify-center min-h-screen">
            <h1 className="text-3xl font-bold mb-4 text-center">Accelerators</h1>
            <p>Click each User Story to see more</p>
            <Gallery /> {/* Add the Gallery component here */}
        </main>
    );
};

export default About;
