import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Logout from './components/Logout';
import Home from './pages/Home';
import About from './pages/About';
import Accelerators from './pages/Accelerators';
import DataConcierge from './pages/DataConcierge';
import { AuthProvider } from './components/AuthContext'; // Adjust the path as necessary

const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/accelerators" element={<Accelerators />} />
          <Route path="/dataconcierge" element={<DataConcierge />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          {/* Add more routes for Services and Contact pages here */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
