// Header component
// Import React library
import React, { useState } from 'react';
// Import Link component for creating navigation links that work without reloading current page
import { Link } from 'react-router-dom';

// Define Header component as arrow function
const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // State to manage the menu open/close
  const [loggedIn, setLoggedIn] = useState(false); // State to manage user login status

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the menu open/close state
  };

  return (
    <header className="p-4 bg-customGrey2 text-white">
      <div className="container mx-auto flex items-center justify-between p-4">
        <img 
          className="w-24 h-auto mr-4 rounded-lg shadow-lg" 
          src="../images/dataconcierge_profile_image.png" 
          alt="Data Concierge Logo" 
        />
        <button 
          className="md:hidden focus:outline-none" 
          onClick={toggleMenu}
        >
          {/* Hamburger Icon */}
          <svg 
            className="w-6 h-6" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
        <nav className={`md:flex ${isOpen ? 'block' : 'hidden'} absolute md:static bg-customGrey2 w-full md:w-auto`}>
          <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 p-4 md:p-0">
            <li><Link to="/" className="hover:text-blue-600" onClick={() => setIsOpen(false)}>Home</Link></li>
            <li><Link to="/about" className="hover:text-blue-600" onClick={() => setIsOpen(false)}>About</Link></li>
            <li><Link to="/accelerators" className="hover:text-blue-600" onClick={() => setIsOpen(false)}>Accelerators</Link></li>
            <li><Link to="/dataconcierge" className="hover:text-blue-600" onClick={() => setIsOpen(false)}>Data Concierge</Link></li>
            <li><Link to="/signup" className="hover:text-blue-600" onClick={() => setIsOpen(false)}>Sign Up</Link></li>
            {!loggedIn ? (
              <li><Link to="/login" className="hover:text-blue-600" onClick={() => setIsOpen(false)}>Log In</Link></li>
            ) : (
              <li><Link to="/logout" className="hover:text-blue-600" onClick={() => setIsOpen(false)}>Log Out</Link></li>
            )}
            <li>
              <a href="mailto:info@genesisnmc.net" className="hover:text-blue-600" onClick={() => setIsOpen(false)}>
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

// Export Header component for use in other modules
export default Header;
