// Footer component
// Import React libary
import React from 'react';

// Define Footer component as arrow function
const Footer = () => {
  return (
    <footer className="p-4 text-black mt-auto">
      <div className="container mx-auto p-4 text-center">
        <a href="mailto:info@genesisnmc.net" className="text-blue-600 hover:underline">info@genesisnmc.net</a><br />
        <span className="text-sm">© Navarro Management Consulting Limited, 2024 | 3rd Floor 86-90 Paul Street, London, United Kingdom, EC2A 4NE | Company number 09536547 England & Wales</span>
      </div>
    </footer>
  );
};

// Export Header component for use in other modules
export default Footer;
