// DataConcierge
import React from 'react';
import { Link } from 'react-router-dom';

const promptCards = [
    "Analyse the Data Quality of a file I upload",
    "Tell me a joke!",
    "Check a list of VAT numbers for validity",
    "Create a Information Maturity Assessment Survey",
    "Create an automated workflow for document sign-off",
    "Help me write a Data Migration Strategy"
];

const DataConcierge = () => {
    return (
        <main className="flex flex-col items-center justify-center min-h-screen p-4">
            <h1 className="text-3xl font-bold mb-4 text-center">
                The Data Concierge App is coming soon...
            </h1>
            <form className="flex items-center space-x-2 w-3/4 mb-8">
                <input
                    type="text"
                    className="flex-grow p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="Type your request here..."
                />
                <button
                    type="submit"
                    className="px-4 py-2 text-white bg-customGrey2 rounded-lg hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                    Go
                </button>
            </form>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-3/4">
                {promptCards.map((prompt, index) => (
                    <div key={index} className="p-4 border border-gray-300 rounded-lg shadow-md text-center">
                        {prompt}
                    </div>
                ))}
            </div>
        </main>
    );
};

export default DataConcierge;
