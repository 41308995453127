// src/components/LogoutButton.js
import React from 'react';
import { logOut } from '../auth'; // Adjust the path as necessary

const LogoutButton = () => {
  const handleLogout = async () => {
    try {
      await logOut();
      alert('User logged out successfully!');
      // Optionally, redirect to the login page or home page
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <button
      onClick={handleLogout}
      className="p-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200"
    >
      Log Out
    </button>
  );
};

export default LogoutButton;
