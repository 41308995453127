import React, { useState } from 'react';

const Accordion = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="border border-gray-300 rounded-lg mb-4">
            <button 
                onClick={toggleOpen} 
                className={`flex justify-between items-center w-full p-4 text-left rounded-lg hover:bg-green-500 focus:outline-none ${isOpen ? 'bg-green-500 text-white' : 'bg-gray-200 text-black'}`}
            >
                <span className="font-semibold">{title}</span>
                <svg 
                    className={`w-6 h-6 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
            </button>
            {isOpen && (
                <div className="p-4 bg-green-100">
                    <p>{content}</p>
                </div>
            )}
        </div>
    );
};

export default Accordion;
