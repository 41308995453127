import React from 'react';
import ExpandingBar from '../components/AboutMeAccordion';
import Accordion from '../components/AboutMeAccordion';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <main className="min-h-screen p-4">
            <div className="mb-4 flex flex-col md:flex-row"> {/* Use Flexbox for layout */}
                <div className="flex-shrink-0 mr-4"> {/* Prevents the image from shrinking */}
                    <img
                        className="w-72 h-72 rounded-full border-4 border-gray-300"
                        src="../images/AN_profilepic1.JPG"
                        alt="Alvin Navarro"
                    />
                    <p className="text-center mt-2 font-bold">Alvin Navarro</p>
                </div>
                <div className="flex-grow"> {/* Allows the text to take the remaining space */}
                    <h1 className="text-3xl font-bold mb-4 text-center">About Me</h1>
                    <p className="mt-4">
                        Alvin is a highly skilled, innovative expert in Data Engineering, Data Science, Finance & SAP Systems Implementations.<br />
                        As a qualified Chartered Accountant, he expertly blends his business acumen with technical knowledge to communicate impactfully and partner effectively with a wide range of stakeholders.<br />
                        Past clients include Marks & Spencer, LIPTON Teas & Infusions, Unilever, NTT Data, HSBC, Sky, Vodafone and more.
                    </p>
                    <h4 className="mt-4 font-semibold">Key Skills:</h4>
                    <ul className="list-disc list-inside">
                        <li>Data transformation, cleansing, analysis and visualisation of Big Data.</li>
                        <li>Deep Subject Matter Expertise in the Microsoft stack, SAP, SQL, Python, Excel and more.</li>
                        <li>Financial Planning & Analysis</li>
                        <li>Business Process Automation and Optimisation</li>
                        <li>Highly adept at both Waterfall and Agile Project Management techniques.</li>
                    </ul>
                </div>
            </div>
            <div className="min-h-screen p-8">
                <Accordion
                    title="Data Science & Software Engineering"
                    content={
                        <ul className="list-disc list-inside pl-5"> {/* Add Tailwind classes for styling */}
                            <li>Microsoft Certified: Azure AI Fundamentals</li>
                            <li>Data Science: Python, Jupyter Notebooks, Microsoft Power Query, Microsoft Fabric</li>
                            <li>Data Visualisation: Google Data Studio, Microsoft Power BI, SAP Analytics Cloud</li>
                            <li>SQL: SQLite, MySQL, MariaDB, PostgreSQL</li>
                            <li>Neo4J Certified Professional</li>
                            <li>NoSQL: Neo4J, Cypher, MongoDB</li>
                            <li>Google Cloud Platform: APIs, App Engine, Cloud Storage</li>
                            <li>JavaScript</li>
                            <li>Excel VBA</li>
                        </ul>
                    }
                />
                <Accordion
                    title="Finance & Accounting"
                    content={
                        <ul className="list-disc list-inside pl-5"> {/* Add Tailwind classes for styling */}
                            <li>Chartered Accountant: Chartered Accountants Australia & New Zealand</li>
                            <li>Completed Chartered Financial Analyst Level I Exam</li>
                            <li>Bachelor of Commerce (Accounting) and Arts (Economics): University of Auckland, New Zealand</li>
                        </ul>
                    } />
                <Accordion
                    title="Project Management"
                    content={
                        <ul className="list-disc list-inside pl-5"> {/* Add Tailwind classes for styling */}
                            <li>Professional Scrum Master™ I</li>
                            <li>SAP® Certified Associate: Activate Project Manager</li>
                            <li>PMI® Project Management Professional (2017-2022)</li>
                            <li>PRINCE2® Practitioner (2009-2020)</li>
                        </ul>
                    } />
                <Accordion
                    title="Business Process Transformation"
                    content={
                        <ul className="list-disc list-inside pl-5"> {/* Add Tailwind classes for styling */}
                            <li>SAP® Certified Associate: Design Thinking</li>
                            <li>Microsoft® 365: Power Automate, Forms, Planner, Power Platform, SharePoint, Teams</li>
                            <li>BPMN2.0: Business Process Modelling Notation</li>
                            <li>UML: Unified Modelling Language</li>
                        </ul>
                    } />
            </div>
        </main>
    );
};

export default About;
