// Import React libary and useState hook with destructuring syntax to only get specific item from library
import React, { useState } from 'react';

// Define images and texts as objects
const images = [
    {
        src: '../images/casestudy1-dma.png',
        label: 'Data Maturity Assessment',
        text: 'Data Maturity Assessment questionnaire in Microsoft Forms based on industry best practices. \
        360 degree assessment of Data Maturity from cross-functional population and across multiple job levels. \
        Automated analytics via Microsoft Power Automate & Power Query. Automated dashboard in Microsoft Power BI.',
    },
    {
        src: '../images/casestudy2-rpa.png',
        label: 'RPA for Project Deliverables',
        text: 'Robot Process Automation (RPA) for issuing, tracking, reviewing, reporting, notifying, approving and signing off \
        Deliverables using Microsoft Power Automate and Microsoft Forms.\
        Instant notifications via Outlook, Teams, Chat Bots etc, automatic workflow to next person in the chain, \
        Approved/Rejected decision gates, and electronic audit trail.',
    },
    {
        src: '../images/casestudy3-dq.png',
        label: 'Data Quality Profiling & Cleansing',
        text: 'Rapid and intelligent Data Quality Profiling and Cleansing of your data in preparation for migration to SAP S/4HANA \
        via SQL, Python and Microsoft Power Query. Dynamic analysis and visualisation via Microsoft Power BI \
        Robust and digital Data Governance workflows via Microsoft Power Automate. Tried & Tested: not trial & error.',
    },
    {
        src: '../images/casestudy4-training.png',
        label: 'Training & Guided Development',
        text: 'Targeted and focussed on client’s use case; much more effective than generic online training. \
        Run training for a team, geography, Business Unit, project to meet a specific goal. \
        Increase in-house skill levels and productivity. \
        Leverage your existing software licences for instant Payback & Return on Investment.',
    }
];

const Gallery = () => {
    // Destructure array with square brackets
    // Initialise variable visibleIndex with null state
    const [visibleIndex, setVisibleIndex] = useState(null); // State to track which image's text is visible

    const handleImageClick = (index) => {
        // Toggle visibility of the text for the clicked image with ternary syntax
        // Is image is showing? Then hide text, else show text 
        setVisibleIndex(visibleIndex === index ? null : index);
    };

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
            {images.map((image, index) => (
                <div key={index} className="flex flex-col items-center overflow-hidden rounded-lg shadow-lg">
                    <div className="p-4">
                        <h3 className="text-lg font-semibold text-center">{image.label}</h3> {/* Label above the image */}
                    </div>
                    <img
                        src={image.src}
                        alt={`Gallery Image ${index + 1}`}
                        className="w-48 h-auto cursor-pointer"
                        onClick={() => handleImageClick(index)} // Handle click event
                    />
                    {visibleIndex === index && ( // Conditionally render the text
                        <div className="p-4">
                            <p className="">{image.text}</p> {/* Description text */}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Gallery;
